var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_sales_order_detail") } },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            model: _vm.detail,
            "wrapper-col": { span: 12 },
            "label-col": { span: 9 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_document_number") } },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.documentNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "documentNumber", $$v)
                          },
                          expression: "detail.documentNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_status") } },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.states,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "states", $$v)
                          },
                          expression: "detail.states"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_sales_type") } },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.salesType,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "salesType", $$v)
                          },
                          expression: "detail.salesType"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.isSalesRent
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_internal_contract_number")
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.detail.internalContractNo,
                              callback: function($$v) {
                                _vm.$set(_vm.detail, "internalContractNo", $$v)
                              },
                              expression: "detail.internalContractNo"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_branch") } },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.branchWarehouse,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "branchWarehouse", $$v)
                          },
                          expression: "detail.branchWarehouse"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_date") } },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          disabled: ""
                        },
                        model: {
                          value: _vm.detail.date,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "date", $$v)
                          },
                          expression: "detail.date"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_customer"), span: 3 } },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.customerName,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "customerName", $$v)
                          },
                          expression: "detail.customerName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_ship_address"), span: 3 } },
                    [
                      _c("a-textarea", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.customerShipToAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "customerShipToAddress", $$v)
                          },
                          expression: "detail.customerShipToAddress"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_bill_address"), span: 3 } },
                    [
                      _c("a-textarea", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.customerBillToAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "customerBillToAddress", $$v)
                          },
                          expression: "detail.customerBillToAddress"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_sales_name")
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.salesName,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "salesName", $$v)
                          },
                          expression: "detail.salesName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_tax_calculation")
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.taxCalculation,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "taxCalculation", $$v)
                          },
                          expression: "detail.taxCalculation"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_customer_po_number")
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.customerPoNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "customerPoNumber", $$v)
                          },
                          expression: "detail.customerPoNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_estimate_delivery_date")
                      }
                    },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          disabled: ""
                        },
                        model: {
                          value: _vm.detail.deliveryDate,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "deliveryDate", $$v)
                          },
                          expression: "detail.deliveryDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_currency")
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.currency,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "currency", $$v)
                          },
                          expression: "detail.currency"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_currency_rate")
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.currencyRate,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "currencyRate", $$v)
                          },
                          expression: "detail.currencyRate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_term_of_payment")
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.termOfPayment,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "termOfPayment", $$v)
                          },
                          expression: "detail.termOfPayment"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_notes")
                      }
                    },
                    [
                      _c("a-textarea", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.notes,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "notes", $$v)
                          },
                          expression: "detail.notes"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        "label-align": "right",
                        label: _vm.$t("lbl_close_reason")
                      }
                    },
                    [
                      _c("a-textarea", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.detail.reasonToClose,
                          callback: function($$v) {
                            _vm.$set(_vm.detail, "reasonToClose", $$v)
                          },
                          expression: "detail.reasonToClose"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          "data-source": _vm.detail.salesOrderLines,
          columns: _vm.getColumns,
          "row-key": function(r) {
            return r.id
          },
          pagination: {
            showTotal: function(total) {
              return _vm.$t("lbl_total_items", { total: total })
            },
            showSizeChanger: true
          },
          scroll: { x: "calc(1200px + 100%)" },
          size: "small"
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_c("span", [_vm._v(_vm._s(_vm.$t("lbl_product")))])]
            },
            proxy: true
          },
          {
            key: "currency",
            fn: function(text) {
              return _c("span", {}, [
                _vm._v(" " + _vm._s(_vm._f("currency")(text)) + " ")
              ])
            }
          },
          {
            key: "number",
            fn: function(text) {
              return _c("span", {}, [
                _vm._v(" " + _vm._s(_vm._f("qty")(text)) + " ")
              ])
            }
          },
          {
            key: "nullable",
            fn: function(text) {
              return _c("span", {}, [_vm._v(" " + _vm._s(text || "-") + " ")])
            }
          },
          {
            key: "boolean",
            fn: function(text) {
              return _c(
                "span",
                {},
                [_c("DisplayBoolean", { attrs: { value: text, mode: "yes" } })],
                1
              )
            }
          }
        ])
      }),
      _c("a-divider"),
      _c("DisplayTotal"),
      _c("a-divider"),
      _c(
        "div",
        { staticClass: "mt-2 text-right" },
        [
          _c("a-button", { on: { click: _vm.handleBack } }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }