














































































































































import { DisplayBoolean } from "@/components/DisplayBoolean";
import { DisplayTotal } from "@/components/SalesOrder";
import { useSalesOrder } from "@/hooks";
import { ONE } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { SalesOrderResponseDto } from "@/models/interface/sales-order";
import { FormValue } from "@/store/salesOrder.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations } from "vuex";

@Component({
  beforeRouteLeave(_to, _from, next) {
    this.resetStore();
    next();
  },
  components: {
    DisplayBoolean,
    DisplayTotal,
  },
  computed: {
    ...mapGetters("salesOrderStore", [
      "isSalesRent",
      "isSalesProductSale",
      "isSalesOther",
      "isAssetSale",
    ]),
  },
  methods: {
    ...mapMutations({
      setForm: "salesOrderStore/setForm",
      setSoLines: "salesOrderStore/setSoLines",
    }),
    ...mapActions({
      resetStore: "salesOrderStore/resetStore",
    }),
  },
})
export default class DetailPage extends Vue {
  ONE = ONE;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  isSalesRent!: boolean;
  isSalesProductSale!: boolean;
  isSalesOther!: boolean;
  isAssetSale!: boolean;
  setForm!: (payload: Partial<FormValue>) => void;
  resetStore!: () => void;

  @Prop({ type: String, default: "" })
  id!: string; // sales order secure id

  detail: SalesOrderResponseDto = useSalesOrder().initDetailDto();

  commonColumns = [
    {
      title: this.$t("lbl_part_number_unit_code"),
      dataIndex: "productCode",
      width: "200px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      width: "200px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      width: "200px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_customer_location"),
      dataIndex: "customerLocation",
      width: "200px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_qty_outstanding"),
      dataIndex: "qtyOutstanding",
      width: "200px",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_discount"),
      dataIndex: "discountValue",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_dpp"),
      dataIndex: "taxableValue",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_tax_amount"),
      dataIndex: "taxValue",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_tax_code"),
      dataIndex: "taxCode",
      width: "250px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_subtotal"),
      dataIndex: "subTotal",
      scopedSlots: { customRender: "currency" },
    },
  ];

  columnsRent = [...this.commonColumns];

  columnsProductSale = [...this.commonColumns];

  columnsSoOther = [...this.commonColumns];

  columnsAssetSale = [...this.commonColumns];

  mounted(): void {
    this.modifyColumn();
    this.getDetail(this.id);
  }

  get getColumns() {
    if (this.isSalesRent) return [...this.columnsRent];
    else if (this.isSalesProductSale) return [...this.columnsProductSale];
    else if (this.isSalesOther) return [...this.columnsSoOther];
    else if (this.isAssetSale) return [...this.columnsAssetSale];
    else return [...this.commonColumns];
  }

  getDetail(id: string): void {
    const { findById, mapDetailToForm } = useSalesOrder();
    findById(id).then(res => {
      this.detail = res;
      mapDetailToForm(res);
    });
  }

  handleBack(): void {
    this.$router.push({
      name: "sales.sales-order",
    });
  }

  /**
   * @description insert specific column to common column
   */
  modifyColumn(): void {
    // column rent
    // insert backup unit column, and location at 2nd index
    this.columnsRent.splice(
      2,
      0,
      {
        title: this.$t("lbl_backup_unit"),
        dataIndex: "backupUnit",
        scopedSlots: { customRender: "boolean" },
      },
      {
        title: this.$t("lbl_location"),
        dataIndex: "locationName",
        width: "200px",
        scopedSlots: { customRender: "nullable" },
      }
    );

    // product sale columns
    // insert uom, location, qty available
    this.columnsProductSale.splice(
      2,
      0,
      {
        title: this.$t("lbl_uom"),
        dataIndex: "uomName",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_location"),
        dataIndex: "locationName",
        width: "200px",
        scopedSlots: { customRender: "location" },
      },
      {
        title: this.$t("lbl_qty_available"),
        dataIndex: "qtyAvailable",
        scopedSlots: { customRender: "number" },
      }
    );

    // so other
    // insert uom, qty available
    this.columnsSoOther.splice(
      3,
      0,
      {
        title: this.$t("lbl_uom"),
        dataIndex: "uomName",
        scopedSlots: { customRender: "nullable" },
      },
      {
        title: this.$t("lbl_qty_available"),
        dataIndex: "qtyAvailable",
        scopedSlots: { customRender: "number" },
      }
    );

    // so asset sale
    // insert location
    this.columnsAssetSale.splice(2, 0, {
      title: this.$t("lbl_location"),
      dataIndex: "locationName",
      width: "200px",
      scopedSlots: { customRender: "nullable" },
    });
    // so asset sale
    // insert location
    this.columnsAssetSale.splice(4, 0, {
      title: this.$t("lbl_qty_available"),
      dataIndex: "qtyAvailable",
      scopedSlots: { customRender: "number" },
    });
  }
}
